import React from 'react';
// import './style.css';
import './style.css';

const CustomerReview = () => {

    const reviewsData = [
        {
            id: 1,
            quote: "Absolutely thrilled with this ebook bundle! The variety of topics covered has significantly boosted my spoken English skills. The engaging content and practical tips make it a must-have for anyone looking to communicate more effectively. A fantastic investment in self-improvement!",
            name: "Priya Sharma",
            image: '/images/review/Priya-Sharma.png'
        },
        {
            id: 2,
            quote: "This ebook bundle is a game-changer! The diverse range of materials has not only improved my English fluency but has also sharpened my overall communication skills. The convenience of having 25 comprehensive resources in one bundle is unmatched. Highly recommend it to anyone on the journey of self-improvement!",
            name: "Rajiv Kapoor",
            image: '/images/review/Rajiv-Kapoor.png'
        },
        {
            id: 3,
            quote: "I can't express how much I've gained from this ebook bundle. The authors have done an excellent job addressing various aspects of spoken English, making it easy to grasp and apply in real-life situations. The value for money is exceptional, and I've seen a noticeable difference in my communication skills. Two thumbs up!",
            name: "Sneha Gupta",
            image: '/images/review/Sneha-Gupta.png'
        },
    ];
    return (
        <div className='total-review-background'>
            <div className='actual-review-content'>
                <p className='review-header'>
                    Don’t just take my word for it, hear <span>what our customers say...</span>
                </p>
                <div className='review-image-grid-container'>
                    {/* <div className='single-review-contatiner'>
                        <h1>"Absolutely thrilled with this ebook bundle! The variety of topics covered has significantly boosted my spoken English skills. The engaging content and practical tips make it a must-have for anyone looking to communicate more effectively. A fantastic investment in self-improvement!"</h1>
                        <div className='reviewer-name'>
                            <p>-  Priya Sharma</p>
                        </div>
                    </div> */}
                    {reviewsData.map((review) => (
                        <div key={review.id} className='single-review-contatiner'>
                            <h1>{review.quote}</h1>
                            <div className='reviewer-name'>
                                <img src={review.image} className='review-image' />
                                <p>- {review.name}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CustomerReview
