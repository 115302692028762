import React from 'react';
import './style.css';

const WaitAdd = () => {
    const newplan = "https://pages.razorpay.com/pl_NRlQPYu4TbrLJt/view"
    const handleclick = () => {
        window.location.assign(newplan);
    }
    return (
        <div className='total-wait-container'>
            <div className='wait-container-content'>
                <div className='top-content'>
                    <div className='header-content'>
                        <div className='header-text'>
                            <h1>Wait</h1>
                            <p>That’t not all “Plus if you enroll now”</p>
                        </div>
                        <p className='header-sub-text-2'>If you register before midnight of<span> February 28,  2025 </span>you’ll get
                            access to these exclusive bonuses</p>
                    </div>
                    <p className='motive-text'>Get 100 ways to motivate yourself ebook for <span>totally free</span></p>
                </div>
                <div className='down-content'>
                    <div className='image-container'>
                        <img src='/images/Card-Images/100-ways-to-motivate-yourself-ebook-cover.png' alt='image' className='image-style' />
                    </div>
                    <div className='content-container'>
                        <div className='header-content'>
                            <p className='text'>Original price is<span> Rs3999/-</span>but today you will get it for free</p>
                            <div className='value-button' onClick={handleclick}>
                                <p className='letter-style'><span>Rs.3999 </span> Free</p>
                                <p className='sub-text'>Offer available for limited time</p>
                            </div>
                        </div>
                        <div className='bottom-text'>
                            <p className='small-text'>hurry!</p>
                            <p className='big-text'>Few minutes left</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WaitAdd
