import React from 'react';
import './style.css'
import Card from '../../components/BestPlanCard/card';

const data = [
    {
        title: "Basic plan",
        description: "get instant access of 17+ english spoken ebook bundle",
        img: "/images/Card-Images/17+-Ebook-for-your-Spoken-English.png",
        realPrice: "Rs.4999",
        offerPrice: "Rs.99/-",
        plan: "https://pages.razorpay.com/pl_NRlQPYu4TbrLJt/view"
    },
    {
        title: "Premium plan",
        description: "17+ English spoken ebooks + English speaking video course from \n" +
            "beginner to advanced level + including bonus",
        img: "/images/Card-Images/195+Lessons-_with-laptop.png",
        realPrice: "Rs.12999",
        offerPrice: "Rs.499/-",
        plan: "https://pages.razorpay.com/pl_NRlSLYPqOMoiGe/view"
    }
]
const BestPlan = () => {
    return (
        <>
            <section className={"best-plan"}>
                <div className={"best-plan-title"}>Choose the best plan for yourself</div>
                <div className="best-plan-content">
                    <div className="best-plan-card-wrapper">
                        {data.map((ele, id) => (
                            <Card title={ele.title} description={ele.description} img={ele.img} key={id} offerPrice={ele.offerPrice} realPrice={ele.realPrice} plan={ele.plan} />
                        ))}

                    </div>
                </div>
            </section>
        </>
    );
};

export default BestPlan;