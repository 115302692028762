import React from 'react';
import './style.css'

const Achievement = () => {
    const newplan = "https://pages.razorpay.com/pl_NRlQPYu4TbrLJt/view"
    const handleclick = () => {
        window.location.assign(newplan);
    }
    return (
        <div className='total-achievement-container'>
            <div className='achievement-main-container'>
                <div className='main-header-container'>
                    <div className='left-container'>
                        <div>
                            <img src="/images/satisfaction.png" alt='satisfaction' className='image-style' />
                        </div>
                        <p className='left-text'>It can be a freat deal for you! After payment, you will receive your access details to your registered email.</p>
                    </div>
                    <div className='right-container'>
                        <div className='text-with-button-container'>
                            <div className='insider-texts'>
                                <div className='header-text'>
                                    <p className='first-text'>Only today...You will get...</p>
                                    <div className='subtext'>
                                        <p className='second-text'>Lifetime Free updates & access + Unlimited Download</p>
                                        <p className='second-text'>Llifetime Full access with updates</p>
                                    </div>
                                </div>
                                <div className='middle-txt'>
                                    <p className='third-text'>Rs.999/-</p>
                                    <span className='span-text'>Today you will get Rs.99/-</span>
                                </div>
                            </div>
                            <button className='buy-button' onClick={handleclick}>buy now</button>
                        </div>
                        <p className='fourth-text'>After payment, you will receive your access details to your registered email.</p>
                    </div>
                </div>
                <div className='disclaimer-container'>
                    <div className='line'></div>
                    <div className='disclaimer-header-and-subtext'>
                        <div className='Disclaimer-border'>Disclaimer</div>
                        <p className='disclaimer-subtext'>This site is not a part of whatsApp or Facebook. Mentional names or logos are properties of their respective companies.
                            The information on this websites is for educational purposes only, we neither support nor be held responsible for any misuse of this info. We are affiliated with any brand or website .
                            Buyer must use the files responsibly and adhere to the website terms or usage policy (or whatever applicable). </p>
                    </div>
                    <div className='line'></div>
                    {/* <div className='footer'>
                        <p>Privacy Policy</p>
                        <p>|</p>
                        <p>Terms & Conditions</p>
                        <p>|</p>
                        <p>Refund Policy</p>
                        <p>|</p>
                        <p>Contact Us</p>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Achievement
