import React from 'react';
import './style.css';
import List from '../List';

const data = [
    {
        id: 1,
        text: "17+ English spoken ebook bundle"
    },
    {
        id: 2,
        text: "English spoken video course (25+ Lectures)"
    },
    {
        id: 3,
        text: "100 ways to motivate yourself (FREE EBOOK)"
    },
    {
        id: 4,
        text: "Productivity and time management video course (Free Bonus)"
    },
    {
        id: 5,
        text: "One time payment"
    },
    {
        id: 6,
        text: "Free lifetime updates"
    }, {
        id: 7,
        text: "Unlimited download"
    }, {
        id: 8,
        text: "Instant delivery"
    },

]
const FeatureCard = ({ realPrice, offerPrice }) => {
    return (
        <div className={"feature-card"}>
            <div className="feature-card-title">
                Features
            </div>
            <div className="feature-lists">
                {
                    data.map((ele) => (
                        <List text={ele.text} key={ele.id} />
                    ))
                }
            </div>

            <div className={"price"}>
                <div className="real-price">
                    {realPrice}
                </div>
                <div className="offer-price">
                    {offerPrice}
                </div>
            </div>

            <div className="feature-last">
                <button className={"btn-buy"}>Buy Now</button>
                <p>**Offer valid only for first 50 customers</p>
            </div>
        </div>
    );
};

export default FeatureCard;