import React from 'react'
import './style.css';
import { FaBook } from "react-icons/fa";

const GridBooks = () => {


    const normalplan = "https://pages.razorpay.com/pl_NRlQPYu4TbrLJt/view"

    const handleclick = () => {
        window.location.assign(normalplan);
    }

    const booksData = [
        {
            id: 1,
            imageSrc: '/images/books/Foundations-of-Spoken-English.png',
            bookCount: 'E-Book:',
            cardText: 'Foundations of Spoken English'
        },
        {
            id: 2,
            imageSrc: '/images/books/Essential-Vocabulary-for-Everyday-Communication.png',
            bookCount: 'E-Book:',
            cardText: 'Essential Vocabulary for Everyday Communication'
        },
        {
            id: 3,
            imageSrc: '/images/books/Perfecting-Your-Accent.png',
            bookCount: 'E-Book:',
            cardText: 'Perfecting Your Accent'
        },
        {
            id: 4,
            imageSrc: '/images/books/Mastering-Basic-Conversations.png',
            bookCount: 'E-Book:',
            cardText: 'Mastering Basic Conversations'
        },
        {
            id: 5,
            imageSrc: '/images/books/Grammar-Essentials-for-Spoken-English.png',
            bookCount: 'E-Book:',
            cardText: 'Grammar Essentials for Spoken English'
        },
        {
            id: 6,
            imageSrc: '/images/books/Effective-Listening-Skills.png',
            bookCount: 'E-Book:',
            cardText: 'Effective Listening Skills'
        },
        {
            id: 7,
            imageSrc: '/images/books/Expressing-Opinions-and-Ideas.png',
            bookCount: 'E-Book:',
            cardText: 'Expressing Opinions and Ideas'
        },
        {
            id: 8,
            imageSrc: '/images/books/Polishing-Pronunciation.png',
            bookCount: 'E-Book:',
            cardText: 'Polishing Pronunciation'
        },
        {
            id: 9,
            imageSrc: '/images/books/Idioms-and-Expressions-in-Context.png',
            bookCount: 'E-Book:',
            cardText: 'Idioms and Expressions in Context'
        },
        {
            id: 10,
            imageSrc: '/images/books/Advanced-Conversational-Strategies.png',
            bookCount: 'E-Book:',
            cardText: 'Advanced Conversational Strategies'
        },
        {
            id: 11,
            imageSrc: '/images/books/Navigating-Social-Situations.png',
            bookCount: 'E-Book:',
            cardText: 'Navigating Social Situations'
        },
        {
            id: 12,
            imageSrc: '/images/books/Business-English-Communication.png',
            bookCount: 'E-Book:',
            cardText: 'Business English Communication'
        },
        {
            id: 13,
            imageSrc: '/images/books/Cultural-Fluency-in-English.png',
            bookCount: 'E-Book:',
            cardText: 'Cultural Fluency in English'
        },
        {
            id: 14,
            imageSrc: '/images/books/Effective-Storytelling-in-English.png',
            bookCount: 'E-Book:',
            cardText: 'Effective Storytelling in English'
        },
        {
            id: 15,
            imageSrc: '/images/books/Debating-and-Persuasive-Communication.png',
            bookCount: 'E-Book:',
            cardText: 'Debating and Persuasive Communication'
        },
        {
            id: 16,
            imageSrc: '/images/books/Media-Literacy-and-Communication.png',
            bookCount: 'E-Book:',
            cardText: 'Media Literacy and Communication'
        },
        {
            id: 17,
            imageSrc: '/images/books/Public-Speaking-Mastery.png',
            bookCount: 'E-Book:',
            cardText: 'Public Speaking Mastery'
        },
        {
            id: 18,
            imageSrc: '/images/books/English-for-Academic-Conversations.png',
            bookCount: 'E-Book:',
            cardText: 'English for Academic Conversations'
        },
        {
            id: 19,
            imageSrc: '/images/books/Interview-Skills-in-English.png',
            bookCount: 'E-Book:',
            cardText: 'Interview Skills in English'
        },
        {
            id: 20,
            imageSrc: '/images/books/Advanced-English-Fluency.png',
            bookCount: 'E-Book:',
            cardText: 'Advanced English Fluency'
        },
    ];

    return (
        <div className='total-grid-book-container'>
            <div className='total-grid-container-content'>
                <div className='grid-box-header-text'>
                    <p className='first-text'>Register in next 5 minutes to</p>
                    <h1 className='second-text'>to unlock the 17+ e-books at just Rs. 99/-</h1>
                </div>
                {/* <div className='grid-books-division'>
                    {booksData.map(book => (
                        <div key={book.id} className='single-card'>
                            <img src={book.imageSrc} alt={`book${book.id}`} className='card-image' />
                            <div className='book-count-container'>
                                {book.bookCount}
                            </div>
                            <p className='card-text'>
                                {book.cardText}
                            </p>
                        </div>
                    ))}
                </div> */}
                <div className='grid-books-division'>
                    {booksData.map(book => (
                        <div className='single-grid-book'>
                            <div className='ebook-header'>
                                <FaBook className='icon-style' />
                                <p>{book.bookCount} {book.id}</p>
                            </div>
                            <img src={book.imageSrc} className='grid-box-image' />
                            <p className='bottom-text'>{book.cardText}</p>
                        </div>
                    ))}
                </div>
                <div className='many-more-text'>
                    <h1 className='text'>+ Many More... Time is running out.</h1>
                    <div className='buynow-button' onClick={handleclick}>Buy it Now</div>
                </div>
            </div>
        </div>
    );
}



export default GridBooks;
